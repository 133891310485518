<template>
    <backoffice-layout :title="customer.fullname">
        <v-card>
            <div class="grid grid-cols-2 gap-4">
                <v-form-group :label="$t('label.firstname')" class="mb-4">
                    <v-form-input :value="customer.firstname" readonly />
                </v-form-group>

                <v-form-group :label="$t('label.lastname')" class="mb-4">
                    <v-form-input :value="customer.lastname" readonly />
                </v-form-group>
            </div>

            <div class="grid grid-cols-2 gap-4">
                <v-form-group :label="$t('label.mobile')" class="mb-4">
                    <v-form-input :value="customer.mobile" readonly />
                </v-form-group>

                <v-form-group :label="$t('label.email')" class="mb-4">
                    <v-form-input :value="customer.email" readonly />
                </v-form-group>
            </div>
        </v-card>
    </backoffice-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    computed: {
        ...mapGetters('admin_customers', [
            'customer',
        ]),
    },

    mounted () {
        if (this.$route.params.id) this.getCustomer(this.$route.params.id);
    },

    methods: {
        ...mapActions('admin_customers', [
            'getCustomer'
        ])
    },
}
</script>
